import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import LoadingPage from "../common/Loading.page"
import { DiscountInitialData } from "./formData"
import { createDiscount, editDiscount, getDiscountById } from "./services/ModulesServices.services"

const EditDiscountPage = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')


    const formik = useFormik({
        initialValues: DiscountInitialData,
        enableReinitialize: true,
        onSubmit: (values) => {
            const param = {
                ...formik.values
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitDiscount(addParam)
            } else {
                updateDiscount(id, param)
            }
            
        },
    });

    const submitDiscount = (body: any) => {
        setLoading(true)
            createDiscount(body)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success("Success create Discount")
                        navigate('/modules/discount-management', { replace: true })
                    }
                })
                .catch((err) => {
                    toast.error("Failed to create Discount")
                })
                .finally(() => {
                    setLoading(false)
                })
            console.log('PAYLOAD SAVE', body)
    }

    const updateDiscount = (id: string, body: any) => {
        setLoading(true)
        editDiscount(id, body)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success update Discount")
                    navigate('/modules/discount-management', { replace: true })
                }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to update Discount")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const fetchDiscountDetail = (id: string) => {
        setLoading(true)
        getDiscountById(id)
            .then((res) => {
                console.log(res)
                const Discount: any = res.data
                Object.entries(Discount).map(([key, values]) => {
                    formik.setFieldValue(`${key}`, values)
                })
            })
            .catch((err) => {
                console.log('RES', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
            fetchDiscountDetail(id)
        }
    }, [navigate])

    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">{`${pageAction === 'ADD' ? 'Add' : 'Edit'} Discount`}</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Discount
                    </span>
                </div>
                <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div className="grid grid-cols-4 gap-2">
                    {/* <div className="flex flex-col">
                            <InputLabel className="text-sm">Customer Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Discount Type"
                            >
                                {[
                                    { label: 'All', value: 'all' },
                                    { label: 'Corporate', value: 'corporate' },
                                    { label: 'Travel Agent', value: 'TA' },
                                    { label: 'Customers', value: 'customers' },

                                ].map((val: any) => (
                                    <MenuItem>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div> */}
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Name</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={formik.values?.name}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Description</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="description"
                                name="description"
                                placeholder="Name"
                                value={formik.values?.description}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Vehicle Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="vehicleType"
                                name="vehicleType"
                                placeholder="Discount Type"
                                value={formik.values?.vehicleType}
                                onChange={formik.handleChange}
                            >
                                {[
                                    { label: 'All', value: 'all' },
                                    { label: 'Specific', value: 'specific' },
                                    { label: 'Sedan', value: 'Sedan' },

                                ].map((val: any) => (
                                    <MenuItem value={val.value}>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Discount Code*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="code"
                                name="code"
                                placeholder="Discount Code"
                                value={formik.values?.code}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Discount Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="type"
                                name="type"
                                placeholder="Discount Type"
                                value={formik.values?.type}
                                onChange={formik.handleChange}
                            >
                                {[
                                    { label: 'Fix Amount Discount', value: 'fix' },
                                    { label: 'Percentage Discount', value: 'Percentage' }
                                ].map((val: any) => (
                                    <MenuItem value={val.value}>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Discount Value</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="discountAmount"
                                name="discountAmount"
                                placeholder="Discount Value"
                                value={formik.values?.discountAmount}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        
                    </div>
                </div>
            </div>
            </form>
        </div>
    )
}

export default EditDiscountPage