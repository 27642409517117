import { faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";

const NewPricePage = () => {
  type tabType = "ARR" | "DEP" | "P2P" | "HRL";
  const tab = [
    { label: "Airport Arrival", value: "ARR" },
    { label: "Airport Departure", value: "DEP" },
    { label: "Point To Point", value: "P2P" },
    { label: "Hourly", value: "HRL" },
  ];

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<tabType>("ARR");

  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div
          className="flex flex-row gap-4 hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className={`h-10 w-10 text-[#333333]`}
          />
          <Typography variant="h5">Price Plan</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faSave} className="mr-4" />
          Save
        </Button>
      </div>
      <div>
        <div className="p-4">
          <div className="flex flex-row">
            {tab.map((tabs) => (
              <div
                onClick={() => setActiveTab(tabs.value as tabType)}
                className={`hover:cursor-pointer hover:opacity-80 
                        ${
                          tabs.value === activeTab
                            ? "bg-[#0A2638]"
                            : "bg-[#EDBA37]"
                        }
                        ${
                          tabs.value === activeTab
                            ? "text-[#EDBA37]"
                            : "text-[#0A2638]"
                        }
                        px-8 py-4 drop-shadow-xl rounded-t-lg`}
              >
                {tabs.label}
              </div>
            ))}
          </div>
          {/* {activeTab === "ARR" && <AirportArrival data ={}/>}
          {activeTab === "DEP" && <AirportDeparture data={null} />}
          {activeTab === "P2P" && <PointToPoint />}
          {activeTab === "HRL" && <Hourly />} */}
        </div>
      </div>
    </div>
  );
};

export default NewPricePage;
