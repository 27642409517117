import { faChevronCircleLeft, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Checkbox, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { CountryCodeList } from "../common/DataUtils"
import LoadingPage from "../common/Loading.page"
import { createSupplier, editSupplier, getUserById } from "./services/User.services"
import { AccountDetail } from "./User.model"

const AddNewSupplierManagement = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [countryList, setCountryList] = useState<any[]>([])
    const [stateList, setStateList] = useState<any[]>([])
    const [cityList, setCityList] = useState<any[]>([])
    const [showPw, setShowPw] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')
    const [supplierDetail, setSuppierDetail] = useState<AccountDetail>()

    const initialState: AccountDetail = {
        id: "",
        accountName: "",
        firstName: "",
        lastName: "",
        accountType: "SUPPLIER",
        roleType: null,
        email: "",
        website: "",
        password: "",
        countryCode: "",
        phoneNumber: "",
        location: "",
        isGnetSupplier: false,
        isFeaturedSupplier: false,
        isActive: false,
        tier: '',
        billingDetails: {
            companyName: "",
            contactName: "",
            contactPhone: "",
            contactEmail: "",
            contactAddress: "",
            isCompanyDetailCopy: false
        },
        companyDetails: {
            companyName: "",
            companyAddress: "",
            registrationNumber: "",
            website: "",
            country: "",
            state: "",
            city: ""
        },
        driverDetails: [],
        tripDetails: [],
        creditTermDetails: {
            creditDays: 0,
            creditLimit: 0,
            isUnlimited: false,
            status: false,
            creditAmount: 0
        },
        businessDetails: {
            companyName: "",
            companyAddress: "",
            email: "",
            phoneNumber: "",
            taxNumber: ""
        },
        otherSettings: {
            tripBookingEmail: "",
            tripBookingEmailCc: "",
            businessCertificateImage: null,
            insuranceLiabilityCertificateImage: null
        },
        version: null,
        createdBy: null,
        createdAt: null,
        updatedBy: null,
        updatedAt: null,
        deletedBy: null,
        deletedAt: null,
        gnetProviderName: null,
        gnetRequesterName: null,
        rolesDetails:{
            id: "7a5fc758-d4eb-42b3-b487-cca3dc538443",
            rolesName: "USER",
            rolesDescription: "User Role"
        }
    }

    const getState = (e: any) => {
        setStateList([
            { label: 'State 1', value: '1' },
            { label: 'State 2', value: '2' },
            { label: 'State 3', value: '3' }])
    }

    const getCity = (e: any) => {
        setCityList([
            { label: 'City 1', value: '1' },
            { label: 'City 2', value: '2' },
            { label: 'City 3', value: '3' }])
    }

    const formik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        onSubmit: (values) => {
            const modifiedPhoneNumber = formik.values?.phoneNumber.startsWith('0') ? `${formik.values.countryCode}${formik.values?.phoneNumber.slice(1)}` : formik.values?.phoneNumber;
            const param = {
                ...formik.values,
                phoneNumber: modifiedPhoneNumber
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitSupplier(addParam)
            } else {
                updateSupplier(id, param)
            }
        },
    });

    const fetchSupplierDetail = (id: string) => {
        setLoading(true)
        getUserById(id)
            .then((res) => {
                setSuppierDetail(res.data)
                mapDataToFormik(formik, res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const submitSupplier = (body: any) => {
        setLoading(true)
        createSupplier(body)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success create Supplier")
                    navigate('/user/supplier-management', { replace: true })
                }
            })
            .catch((err) => {
                toast.error("Failed to create Supplier")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)
    }

    const updateSupplier = (id: string, body: AccountDetail) => {
        setLoading(true)
        editSupplier(id, body)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success create Supplier")
                    navigate('/user/supplier-management', { replace: true })
                }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to create Supplier")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const mapDataToFormik = (formik: any, data: AccountDetail) => {
        formik.setFieldValue('id', data?.id)
        formik.setFieldValue('accountName', data?.accountName)
        formik.setFieldValue('countryCode', data?.countryCode)
        formik.setFieldValue('phoneNumber', data?.phoneNumber)
        formik.setFieldValue('email', data?.email)
        formik.setFieldValue('password', data?.password)
        formik.setFieldValue('isFeaturedSupplier', data?.isFeaturedSupplier)

        // BILLING
        formik.setFieldValue('billingDetails.contactName', data?.billingDetails.contactName)
        formik.setFieldValue('billingDetails.contactPhone', data.billingDetails.contactPhone)
        formik.setFieldValue('billingDetails.contactEmail', data.billingDetails.contactEmail)
        formik.setFieldValue('billingDetails.contactAddress', data.billingDetails.contactAddress)

        //COMPANY
        formik.setFieldValue('companyDetails.city', data?.companyDetails.city)
        formik.setFieldValue('companyDetails.companyAddress', data?.companyDetails.companyAddress)
        formik.setFieldValue('companyDetails.companyName', data?.companyDetails.companyName)
        formik.setFieldValue('companyDetails.country', data?.companyDetails.country)
        formik.setFieldValue('companyDetails.registrationNumber', data?.companyDetails.registrationNumber)
        formik.setFieldValue('companyDetails.state', data?.companyDetails.state)
        formik.setFieldValue('companyDetails.website', data?.companyDetails.website)

        // OTHER SETTING
        formik.setFieldValue('otherSettings.tripBookingEmail', data?.otherSettings.tripBookingEmail)
        formik.setFieldValue('otherSettings.tripBookingEmailCc', data?.otherSettings.tripBookingEmailCc)
        formik.setFieldValue('otherSettings.businessCertificateImage', data?.otherSettings.businessCertificateImage)
        formik.setFieldValue('otherSettings.insuranceLiabilityCertificateImage', data?.otherSettings.insuranceLiabilityCertificateImage)

        formik.setFieldValue('tripDetails', data?.tripDetails)
        formik.setFieldValue('creditTermDetails', data?.creditTermDetails)

    }


    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
            fetchSupplierDetail(id)
        }

        setCountryList([
            { label: 'Singapore', value: 'sg' },
            { label: 'Malaysia', value: 'my' },
            { label: 'Indonesia', value: 'id' }])
    }, [navigate])

    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">{`${pageAction === 'ADD' ? 'Add' : 'Edit'} Supplier`}</Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col p-4 mx-8">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">Basic Information</span>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Contact Name*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="accountName"
                                name="accountName"
                                value={formik.values.accountName}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Mobile*</InputLabel>
                            <div className="flex flex-row gap-2">
                                <Select
                                    labelId="countryCode"
                                    id="countryCode"
                                    value={formik.values.countryCode}
                                    onChange={formik.handleChange}
                                    size="small"
                                    className="h-10 mt-2 w-60"
                                    name="countryCode"
                                    defaultValue=""
                                >
                                    {CountryCodeList.map((country) => (
                                        <MenuItem value={country.value}>{country.label}</MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    className="w-70 h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formik.values.phoneNumber}
                                    onChange={formik.handleChange}
                                />
                            </div>

                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Email*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Password*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                type={showPw ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon icon={faEyeSlash} className="hover:cursor-pointer"
                                                onClick={() => { setShowPw(e => !e) }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Tier*</InputLabel>
                            <Select
                                labelId="tier"
                                id="tier"
                                value={formik.values.tier}
                                onChange={formik.handleChange}
                                size="small"
                                className="h-10 mt-2 w-52"
                                name="tier"
                                placeholder="Select"
                                defaultValue=""
                            >
                                <MenuItem value={''}>Select</MenuItem>
                                <MenuItem value={1}>Tier 1</MenuItem>
                                <MenuItem value={2}>Tier 2</MenuItem>
                                <MenuItem value={3}>Tier 3</MenuItem>
                                <MenuItem value={4}>Tier 4</MenuItem>
                                <MenuItem value={5}>Tier 5</MenuItem>
                            </Select>
                        </div>
                        <div className="flex flex-row w-fit">
                            <Checkbox defaultChecked size="small" name="isFeaturedSupplier"
                                onChange={() => {
                                    formik.setFieldValue('isFeaturedSupplier', !formik.values.isFeaturedSupplier)
                                }}
                                checked={Boolean(formik.values.isFeaturedSupplier)} />
                            <a className="align-middle self-center justify-center">Featured Supplier</a>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col p-4 mx-8">
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">Billing Details</span>
                        <span className="text-neutral-800 font-semibold -mb-4 ml-2 bg-white w-fit pr-4 ">
                            <Checkbox defaultChecked size="small" className="bg-white accent-white"
                                onChange={() => { }}
                                onClick={() => {
                                    formik.setFieldValue('billingDetails.contactName', formik.values.accountName)
                                    formik.setFieldValue('billingDetails.contactPhone', formik.values.phoneNumber)
                                    formik.setFieldValue('billingDetails.contactEmail', formik.values.email)
                                }}
                            />
                            Same as user detail</span>
                    </div>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4 h-fit">
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Contact Name*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="billingDetails.contactName"
                                name="billingDetails.contactName"
                                value={formik.values.billingDetails.contactName}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Contact Phone*</InputLabel>
                            <div className="flex flex-row gap-2">
                                <Select
                                    defaultValue=""
                                    title={formik.values.billingDetails.countryCode ?? 'Country Code'}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formik.values.billingDetails.countryCode}
                                    onChange={formik.handleChange}
                                    size="small"
                                    className="h-10 mt-2 w-20"
                                    name="billingDetails.countryCode"
                                >
                                    {CountryCodeList.map((country) => (
                                        <MenuItem value={country.value}>{country.label}</MenuItem>
                                    ))}
                                </Select>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="phone"
                                    name="billingDetails.contactPhone"
                                    value={formik.values.billingDetails.contactPhone}
                                    onChange={formik.handleChange}
                                />
                            </div>

                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Email</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="billingDetails.contactEmail"
                                name="billingDetails.contactEmail"
                                value={formik.values.billingDetails.contactEmail}
                                onChange={formik.handleChange}
                                type="email"
                            />
                        </div>
                        <div className="flex flex-col w-[40%]">
                            <InputLabel className="text-sm -mb-2">Address*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="billingDetails.contactAddress"
                                name="billingDetails.contactAddress"
                                multiline
                                maxRows={2}
                                value={formik.values.billingDetails.contactAddress}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col p-4 mx-8">
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                            Company Detail
                        </span>
                    </div>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4 h-fit">
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Company Name*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="companyDetails.companyName"
                                name="companyDetails.companyName"
                                value={formik.values.companyDetails.companyName}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Company Address*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="companyDetails.companyAddress"
                                name="companyDetails.companyAddress"
                                value={formik.values.companyDetails.companyAddress}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Website</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="companyDetails.website"
                                name="companyDetails.website"
                                value={formik.values.companyDetails.website}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Country*</InputLabel>
                            <Select
                                defaultValue=""

                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formik.values.companyDetails.country}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                size="small"
                                className="h-10 mt-2 w-52"
                                name="companyDetails.country"
                                placeholder="Select"
                            >
                                {countryList.map((_) => (
                                    <MenuItem value={_.value}>{_.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">State*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="companyDetails.state"
                                name="companyDetails.state"
                                value={formik.values.companyDetails.state}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">City*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="companyDetails.city"
                                name="companyDetails.city"
                                value={formik.values.companyDetails.city}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>

                <div className={`flex flex-col p-4 mx-8 ${pageAction === 'ADD' ? 'hidden' : 'visible'}`}>
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                            Other Settings
                        </span>
                    </div>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4 h-fit">
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Trip Booking Email</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="otherSettings.tripBookingEmail"
                                name="otherSettings.tripBookingEmail"
                                value={formik.values.otherSettings.tripBookingEmail}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Booking Email CC</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="otherSettings.tripBookingEmailCc"
                                name="otherSettings.tripBookingEmailCc"
                                value={formik.values.otherSettings.tripBookingEmailCc}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Bussiness Certificate</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="otherSettings.businessCertificateImage"
                                name="otherSettings.businessCertificateImage"
                                value={formik.values.otherSettings.businessCertificateImage}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div className="flex flex-col w-fit">
                            <InputLabel className="text-sm -mb-2">Insurance Liability Certificate</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="otherSettings.insuranceLiabilityCertificateImage"
                                name="otherSettings.insuranceLiabilityCertificateImage"
                                value={formik.values.otherSettings.insuranceLiabilityCertificateImage}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddNewSupplierManagement