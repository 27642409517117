import { faChevronCircleLeft, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, Button, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CountryCodeList } from "../../common/DataUtils";
import LoadingPage from "../../common/Loading.page";
import { createSupplier, editSupplier, getUserById } from "../services/User.services";
import { AccountDetail, CustomerListItem } from "../User.model";
import { CustomerInitialData, exampleData } from "./formData";

const CustomerDetailPage = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [showPw, setShowPw] = useState<boolean>(false)
    const [countryList, setCountryList] = useState<any[]>([])
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')

    const options = [
        { label: 'Singapore 01', value: 'sin01' },
        { label: 'Singapore 02', value: 'sin02' },
        { label: 'Singapore 03', value: 'sin03' }
    ];

    const formik = useFormik({
        initialValues: CustomerInitialData,
        enableReinitialize: true,
        onSubmit: (values) => {
            const modifiedPhoneNumber = formik.values?.phoneNumber.startsWith('0') ? `${formik.values.countryCode}${formik.values?.phoneNumber.slice(1)}` : formik.values?.phoneNumber;
            const param = {
                ...formik.values,
                phoneNumber: modifiedPhoneNumber
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitCustomer(addParam)
            } else {
                updateCustomer(id, param)
            }
            
        },
    });

    const submitCustomer = (body: any) => {
        setLoading(true)
            createSupplier(body)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success("Success create B2C Customer")
                        navigate('/user/customer-management', { replace: true })
                    }
                })
                .catch((err) => {
                    toast.error("Failed to create B2C Customer")
                })
                .finally(() => {
                    setLoading(false)
                })
            console.log('PAYLOAD SAVE', body)
    }

    const updateCustomer = (id: string, body: any) => {
        setLoading(true)
        editSupplier(id, body)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success create B2C Customer")
                    navigate('/user/customer-management', { replace: true })
                }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to create B2C Customer")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const fetchCustomerDetail = (id: string) => {
        setLoading(true)
        getUserById(id)
            .then((res) => {
                console.log(res)
                const customer: CustomerListItem = res.data
                Object.entries(customer).map(([key, values]) => {
                    formik.setFieldValue(`${key}`, values)
                })
            })
            .catch((err) => {
                console.log('RES', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const mapDataToFormik = (formik: any, data: AccountDetail) => {
        formik.setFieldValue('id', data?.id)
        formik.setFieldValue('accountName', data?.accountName)
        formik.setFieldValue('countryCode', data?.countryCode)
        formik.setFieldValue('phoneNumber', data?.phoneNumber)
        formik.setFieldValue('email', data?.email)
        formik.setFieldValue('password', data?.password)
        formik.setFieldValue('isFeaturedSupplier', data?.isFeaturedSupplier)

        // BILLING
        formik.setFieldValue('billingDetails.contactName', data?.billingDetails.contactName)
        formik.setFieldValue('billingDetails.contactPhone', data.billingDetails.contactPhone)
        formik.setFieldValue('billingDetails.contactEmail', data.billingDetails.contactEmail)
        formik.setFieldValue('billingDetails.contactAddress', data.billingDetails.contactAddress)

        //COMPANY
        formik.setFieldValue('companyDetails.city', data?.companyDetails.city)
        formik.setFieldValue('companyDetails.companyAddress', data?.companyDetails.companyAddress)
        formik.setFieldValue('companyDetails.companyName', data?.companyDetails.companyName)
        formik.setFieldValue('companyDetails.country', data?.companyDetails.country)
        formik.setFieldValue('companyDetails.registrationNumber', data?.companyDetails.registrationNumber)
        formik.setFieldValue('companyDetails.state', data?.companyDetails.state)
        formik.setFieldValue('companyDetails.website', data?.companyDetails.website)

        // OTHER SETTING
        formik.setFieldValue('otherSettings.tripBookingEmail', data?.otherSettings.tripBookingEmail)
        formik.setFieldValue('otherSettings.tripBookingEmailCc', data?.otherSettings.tripBookingEmailCc)
        formik.setFieldValue('otherSettings.businessCertificateImage', data?.otherSettings.businessCertificateImage)
        formik.setFieldValue('otherSettings.insuranceLiabilityCertificateImage', data?.otherSettings.insuranceLiabilityCertificateImage)

        formik.setFieldValue('tripDetails', data?.tripDetails)
        formik.setFieldValue('creditTermDetails', data?.creditTermDetails)

    }

    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
            fetchCustomerDetail(id)
        }
    }, [navigate])

    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">{`${pageAction === 'ADD' ? 'Add' : 'Edit'} B2C Customer`}</Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col p-4 mx-8">
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                            Basic Information
                        </span>
                    </div>
                    <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                        <div className="flex flex-row gap-4 p-4">
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Account Name*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">First Name*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="firstName"
                                    name="firstName"
                                    value={formik?.values?.firstName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Last Name*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="lastName"
                                    name="lastName"
                                    value={formik?.values?.lastName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Phone*</InputLabel>
                                <div className="flex flex-row gap-2">
                                    <Select
                                        labelId="countryCode"
                                        id="countryCode"
                                        value={formik.values.countryCode}
                                        onChange={formik.handleChange}
                                        size="small"
                                        className="h-10 mt-2 w-60"
                                        name="countryCode"
                                        defaultValue=""
                                    >
                                        {CountryCodeList.map((country) => (
                                            <MenuItem value={country.value}>{country.label}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formik?.values?.phoneNumber}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Email*</InputLabel>
                                <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                        value={formik?.values?.email}
                                        onChange={formik.handleChange}
                                    />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Password*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="password"
                                    name="password"
                                    value={formik.values?.password}
                                    onChange={formik.handleChange}
                                    type={showPw ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FontAwesomeIcon icon={faEyeSlash} className="hover:cursor-pointer"
                                                    onClick={() => { setShowPw(e => !e) }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-4">
                            <div className="flex flex-col w-1/3">
                                <InputLabel className="text-sm -mb-2">Location*</InputLabel>
                                <Autocomplete
                                    className="w-full"
                                    options={options}
                                    onChange={formik.handleChange}
                                    
                                    renderInput={(params) => (
                                        <TextField {...params} value={formik?.values.location} label={'Search Location...'} variant="outlined" />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    style={{ marginTop: '16px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col p-4 mx-8">
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                            View Trips
                        </span>
                    </div>
                    <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Status & ref no.</TableCell>
                                        <TableCell>Service type</TableCell>
                                        <TableCell>Date & time</TableCell>
                                        <TableCell>Trip amount (S$)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {exampleData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{row.statusAndRefNo}</TableCell>
                                            <TableCell>{row.serviceType}</TableCell>
                                            <TableCell>{new Date(row.dateTime).toLocaleString()}</TableCell>
                                            <TableCell>{row.tripAmount.toFixed(2)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CustomerDetailPage