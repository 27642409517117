export const CouponInitialData: any = {
    "id": "",
    "name": "",
    "couponCode": "",
    "couponType": "",
    "description": "",
    "accountType": "",
    "couponUsed": "",
    "couponUsage": "",
    "couponAmount": "",
    "status": "",
}

export const DiscountInitialData: any = {
    "id": "",
    "name": "",
    "code": "",
    "description": "",
    "type": "",
    "vehicleType": "",
    "discountAmount": "",
    "status": "",
}