import { Loader } from '@googlemaps/js-api-loader'
import '@react-google-maps/api'
import { axiosBooking, axiosInstance } from "../../../services/axiosInstance"


export const getTripList = (status: string) => {
  return axiosBooking.get(`/trip/?pageableSize=10&pageablePage=0&sortingPage=ASC&status=${status}`)
}

export const getVehicleList = () => {
    return axiosInstance.get('/vehicle/?pageableSize=5&pageablePage=0&sortingPage=ASC')
}
export const getVehicleById = (id: string) => {
  return axiosInstance.get(`/vehicle/id/${id}`)
}
export const createVehicle = (payload: any) => {
  return axiosInstance.post('/vehicle/create', payload)
}

export const updateVehicle = (id: string, payload: any) => {
  return axiosInstance.put(`/vehicle/update/id/${id}`, payload)
}

export const getTripDetail = (id: string) => {
    return axiosBooking.get(`/trip/id/${id}`)
}

export const submitTrip = (payload: any) => {
    return axiosBooking.post('/trip/create', payload)
}

export const getDistance = async (origin: { lat: any, lng: any }, destination: { lat: any, lng: any }) => {
    try {
        const service = new window.google.maps.DistanceMatrixService()
        return await service?.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                travelMode: window.google.maps.TravelMode.DRIVING,
            }
        );
    } catch (error) {
        throw (error)
    }

}

 const loader = new Loader({
    apiKey: 'AIzaSyAiPQF2KnUfZpG4DOaoM9j2ouAAWd597oQ',
    libraries: ['places']
  });

 export const calculateDistanceAndDuration = async (origin: any, destination: any) => {
    await loader.load();
  
    const directionsService = new window.google.maps.DirectionsService();
  
    const request = {
      origin: new window.google.maps.LatLng(origin.lat, origin.lng),
      destination: new window.google.maps.LatLng(destination.lat, destination.lng),
      travelMode: window.google.maps.TravelMode.DRIVING
    };
  
    return new Promise((resolve, reject) => {
      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const distance = result?.routes?.[0]?.legs?.[0]?.distance?.text ?? "-";
          const duration = result?.routes?.[0]?.legs?.[0]?.duration?.text ?? "-";
          resolve({ distance, duration });
        } else {
          reject('Error fetching directions');
        }
      });
    });
  };