import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, TextField } from "@mui/material"
import { useFormik } from "formik"
import React, { MouseEvent, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { createPricePlan, updatePricePlan } from "../../../../../services/axiosPayment"
import { SecondTabType } from "../../data/form-data"
import { PricePlan } from "../../model/Price.model"

type Props = {
    data: PricePlan;
  };

const AirportDeparture = ({data}: Props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [tabActive, setTabActive] = useState<SecondTabType>('EXTRA_CHARGE')
    const bottomMenu = [{ label: 'Extra Charge', value: 'EXTRA_CHARGE' }, { label: 'Add On Service', value: 'ADD_ON_SERVICE' }]
    const header = ['Zone', 'Airport', 'Economy 4 seater', 'Economy 6 seater', 'Comfort 7 - Toyota Noah',
        'Comfort 5 seater TOYOTA CAMRY OR EQUIVALENT', 'Bus 13 Seater Hiace', 'Business Sedan E CLASS', 'Business Mpv Alphard',
        'Business Van V Class', 'First Class - Mercedes S class', '40 Seater Bus', 'MERCEDES V Class - Tw',
        'Toyota Alphard - malaysia', 'MERCEDES - EQE E CLASS', 'Toll Rate'
    ]
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')
    const id: string = queryParams.get('id') ?? ''
    const [pricePlanDetail, setPriceDetail] = useState<PricePlan[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: (values) => {
            const param = {
                ...formik.values
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitSellingPrice(addParam)
            } else {
                updateSellingPrice(id, param)
            }
            
        },
    });

    const submitSellingPrice = (body: any) => {
        setLoading(true)
            createPricePlan(body)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success("Success create Price Plan")
                    }
                })
                .catch((err) => {
                    toast.error("Failed to create Price Plan")
                })
                .finally(() => {
                    setLoading(false)
                })
            console.log('PAYLOAD SAVE', body)
    }

    const updateSellingPrice = (id: string, body: any) => {
        // setLoading(true)
        updatePricePlan(id, body)
            .then((res) => {
                // if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success update Price Plan")
                // }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to update Price Plan")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const param = {
                ...formik.values
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitSellingPrice(addParam)
            } else {
                updateSellingPrice(id, param)
            }
      };
    
    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
        }
    }, [id])

    return (
        <div className="border-2 border-neutral-700 rounded-lg w-full h-fit p-4">
            <div className="grid grid-cols-6 grid-flow-row gap-4 p-4">
                {/* {header.map((val) => (
                    <div>
                        <InputLabel className="text-sm -mb-2">{val}*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                ))} */}
                <div>
                    <InputLabel className="text-sm -mb-2">Zone*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]zoneCode"
                            name="airportDepartureDetails[0]zoneCode"
                            value={formik.values.airportDepartureDetails[0]?.zoneCode}  
                            onChange={formik.handleChange}
                            
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Airport*</InputLabel>
                    <TextField
                            type="text"
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0].airportCode"
                            name="airportDepartureDetails[0].airportCode"
                            value={formik.values.airportDepartureDetails[0]?.airportCode}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Economy 4 seater*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]economy4SeaterRate"
                            name="airportDepartureDetails[0]economy4SeaterRate"
                            value={formik.values.airportDepartureDetails[0]?.economy4SeaterRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Economy 6 seater*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]economy6SeaterRate"
                            name="airportDepartureDetails[0]economy6SeaterRate"
                            value={formik.values.airportDepartureDetails[0]?.economy6SeaterRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Comfort 7 - Toyota Noah*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]comfort7ToyotaNoahRate"
                            name="airportDepartureDetails[0]comfort7ToyotaNoahRate"
                            value={formik.values.airportDepartureDetails[0]?.comfort7ToyotaNoahRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Comfort 5 seater TOYOTA CAMRY OR EQUIVALENT*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]comfort5SeaterToyotaCamryOrEquivalentRate"
                            name="airportDepartureDetails[0]comfort5SeaterToyotaCamryOrEquivalentRate"
                            value={formik.values.airportDepartureDetails[0]?.comfort5SeaterToyotaCamryOrEquivalentRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Bus 13 Seater Hiace*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]bus13SeaterHiaceRate"
                            name="airportDepartureDetails[0]bus13SeaterHiaceRate"
                            value={formik.values.airportDepartureDetails[0]?.bus13SeaterHiaceRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Business Sedan E CLASS*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]businessSedanEClassRate"
                            name="airportDepartureDetails[0]businessSedanEClassRate"
                            value={formik.values.airportDepartureDetails[0]?.businessSedanEClassRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Business Mpv Alphard*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]businessMpvAlphardRate"
                            name="airportDepartureDetails[0]businessMpvAlphardRate"
                            value={formik.values.airportDepartureDetails[0]?.businessMpvAlphardRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Business Van V Class*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]businessVanVClassRate"
                            name="airportDepartureDetails[0]businessVanVClassRate"
                            value={formik.values.airportDepartureDetails[0]?.businessVanVClassRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">First Class - Mercedes S class*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]firstClassMercedezSClassRate"
                            name="airportDepartureDetails[0]firstClassMercedezSClassRate"
                            value={formik.values.airportDepartureDetails[0]?.firstClassMercedezSClassRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">40 Seater Bus*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]seater40BusRate"
                            name="airportDepartureDetails[0]seater40BusRate"
                            value={formik.values.airportDepartureDetails[0]?.seater40BusRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">MERCEDES V Class - Tw*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]twMercedezVClassRate"
                            name="airportDepartureDetails[0]twMercedezVClassRate"
                            value={formik.values.airportDepartureDetails[0]?.twMercedezVClassRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Toyota Alphard - malaysia*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]malaysiaToyotaAlphardRate"
                            name="airportDepartureDetails[0]malaysiaToyotaAlphardRate"
                            value={formik.values.airportDepartureDetails[0]?.malaysiaToyotaAlphardRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">MERCEDES - EQE E CLASS*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0]eqeEClassMercedezRate"
                            name="airportDepartureDetails[0]eqeEClassMercedezRate"
                            value={formik.values.airportDepartureDetails[0]?.eqeEClassMercedezRate}  
                            onChange={formik.handleChange}
                    />
                </div>
                <div>
                    <InputLabel className="text-sm -mb-2">Toll Rate*</InputLabel>
                    <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="airportDepartureDetails[0].tollRate"
                            name="airportDepartureDetails[0].tollRate"
                            value={formik.values.airportDepartureDetails[0]?.tollRate}  
                            onChange={formik.handleChange}
                    />
                </div>
            </div>
            <div className="flex flex-row px-4">
                {bottomMenu.map((tabs) => (
                    <div onClick={() => setTabActive(tabs.value as SecondTabType)}
                        className={`hover:cursor-pointer hover:opacity-80 
                        ${tabs.value === tabActive ? 'bg-[#0A2638]' : 'bg-[#EDBA37]'}
                        ${tabs.value === tabActive ? 'text-[#EDBA37]' : 'text-[#0A2638]'}
                        px-8 py-4 drop-shadow-xl rounded-t-lg`}>
                        {tabs.label}
                    </div>
                ))}
            </div>
            {tabActive === 'EXTRA_CHARGE' && (
                <div className="grid grid-cols-6 grid-flow-row gap-4 p-4 border-2 border-neutral-700 rounded-lg mx-4">
                    <div>
                        <InputLabel className="text-sm -mb-2">Extra Charge Name*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">From*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">To*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Type*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Rate*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                </div>
            )}
            {tabActive === 'ADD_ON_SERVICE' && (
                <div className="grid grid-cols-6 grid-flow-row gap-4 p-4 border-2 border-neutral-700 rounded-lg mx-4">
                    <div>
                        <InputLabel className="text-sm -mb-2">Addon Service*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Rate*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                </div>
            )}
            <div className="flex flex-row-reverse p-4">
                <Button onClick={handleMouseEvent} className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </Button>
            </div>
        </div>
    )
}

export default AirportDeparture