import { faCopy, faFilePen, faPlus, faSave, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box, Button, CardContent, InputLabel, Modal, Paper,
    Switch, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TextField, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../../services/axiosInstance";
import { getPricePlans, updatePricePlan } from "../../../../services/axiosPayment";
import LoadingPage from "../../../common/Loading.page";
import { PricePlan } from "../model/Price.model";

const SellingPricePage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [pricePlanList, setPricePlanList] = useState<PricePlan[]>([]);
    const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan | null>(null);
    const [showModalPricePlan, setShowModalPricePlan] = useState<boolean>(false);
    const [selectedName, setSelectedName] = useState<string>('');

    const fetchTrip = async () => {
        setLoading(true);
        try {
            const res = await getPricePlans({ pageableSize: 10, pageablePage: 0, sortingPage: 'ASC' });
            setPricePlanList(res?.elements || []);
        } catch (err) {
            handleError(err, navigate);
            setPricePlanList([]);
        } finally {
            setLoading(false);
        }
    };
    
    

    useEffect(() => {
        fetchTrip();
    }, []);

    const handleSaveChanges = async () => {
        if (selectedPricePlan) {
            try {
                await updatePricePlan(selectedPricePlan.id.toString(), { ...selectedPricePlan, code: selectedName });
                const data = await getPricePlans({ pageableSize: 10, pageablePage: 0, sortingPage: 'ASC' });
                if (Array.isArray(data)) {
                    setPricePlanList(data);
                } else {
                    console.error('Data fetched after update is not an array:', data);
                }
                setShowModalPricePlan(false);
            } catch (error) {
                console.error('Error saving changes:', error);
            }
        }
    };

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Price Plan Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Supplier Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button
                                onClick={() => navigate('/price/selling-price/add')}
                                className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2"
                                variant="contained"
                                size="small"
                                style={{ backgroundColor: '#0A2638' }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add New
                            </Button>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CODE</TableCell>
                                        <TableCell>COUNTRY</TableCell>
                                        <TableCell>STATE</TableCell>
                                        <TableCell>CURRENCY</TableCell>
                                        <TableCell>ACTION</TableCell>
                                        <TableCell>STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
    {pricePlanList.length > 0 ? (
        pricePlanList.map((val: PricePlan, idx) => (
            <TableRow key={idx}>
                <TableCell>{val.code}</TableCell>
                <TableCell>{val.countryCode}</TableCell>
                <TableCell>{val.stateCode}</TableCell>
                <TableCell>{val.currencyCode}</TableCell>
                <TableCell>
                        <div className="flex justify-center">
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            title="Copy"
                            onClick={() => {
                              // Add copy functionality
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faFilePen}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            title="Edit"
                            onClick={() => {
                              navigate(`/price/selling-price/edit?id=${val.id}`);
                            }}
                          />
                        </div>
                      </TableCell>
                <TableCell>
                    <Switch
                        size="small"
                        checked={val.status === 'active'}
                        onChange={async () => {
                            try {
                                await updatePricePlan(val.id.toString(), { ...val, status: val.status === 'active' ? 'inactive' : 'active' });
                                fetchTrip(); // Refresh data
                            } catch (error) {
                                console.error('Error toggling status:', error);
                            }
                        }}
                    />
                </TableCell>
            </TableRow>
        ))
    ) : (
        <TableRow>
            <TableCell colSpan={6} align="center">
                No price plans available
            </TableCell>
        </TableRow>
    )}
</TableBody>

                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>
            <Modal
                open={showModalPricePlan}
                onClose={() => setShowModalPricePlan(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex flex-1 flex-col w-screen h-screen justify-center"
            >
                <div className="flex flex-col h-1/3 w-1/3 bg-white rounded-lg self-center p-4 justify-between">
                    <Typography variant="h5">Edit Name</Typography>
                    <div>
                        <InputLabel className="text-sm -mb-2">Name*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="name"
                            name="name"
                            value={selectedName}
                            onChange={(e) => setSelectedName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-row-reverse">
                        <Button className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }} onClick={handleSaveChanges}>
                            <FontAwesomeIcon icon={faSave} />
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SellingPricePage;
