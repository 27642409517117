
export const CorporateInitialData: any = {
    "id": "",
    "accountName": "",
    "firstName": "",
    "lastName": "",
    "accountType": "CORPORATE",
    "roleType": null,
    "email": "",
    "website": "",
    "password": "",
    "phoneNumber": "",
    "location": "",
    // "isGnetSupplier": true,
    // "isFeaturedSupplier": null,
    "billingDetails": null,
    "companyDetails": {
        "companyName": "",
        "companyAddress": "",
        "registrationNumber": "",
        "website": "",
        "country":"",
        "state": "",
        "city": ""
    },
    "driverDetails": null,
    "tripDetails": null,
    "creditTermDetails": null,
    "businessDetails": {
        "companyName": "",
        "companyAddress": "",
        "email": "",
        "phoneNumber": "",
        "taxNumber": ""
    },
    "rolesDetails": {
        "id": "7a5fc758-d4eb-42b3-b487-cca3dc538443",
    },
    "isActive": true,
    "gnetProviderName": null,
    "gnetRequesterName": null
}

export const DummyOptions = [
    { label: 'Singapore 01', value: 'sin01' },
    { label: 'Singapore 02', value: 'sin02' },
    { label: 'Singapore 03', value: 'sin03' }
];