import { faFilePen, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { handleError } from "../../services/axiosInstance"
import LoadingPage from "../common/Loading.page"
import { getCouponsList, updateCoupon } from "./services/ModulesServices.services"

const CouponsManagementPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [couponList, setCouponList] = useState<any[]>([])

    const fetchCouponsList = () => {
        setLoading(true)
        getCouponsList()
            .then((res) => {
                setCouponList(res?.data?.elements)
            })
            .catch((err) => {
                handleError(err, navigate)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChangeStatusCoupon = (param: any) => {
        
        const body = {
            ...param,
            status: param?.status == "Active" ? "Not Active" : "Active",
        }
        setLoading(true)
        updateCoupon(body)
        .then((res) => {
            console.log('RES',res)
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() =>{
            fetchCouponsList()
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchCouponsList()
    }, [])


    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Coupons Management</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Export`}
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => navigate('/modules/coupons-management/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add new
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse py-2">
                        <span className="text-sm">{`Total Coupons: ${couponList.length}`}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-semibold">NAME</span></TableCell>
                                        <TableCell><span className="font-semibold">CUSTOMER TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">CODE</span></TableCell>
                                        <TableCell><span className="font-semibold">TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">DISCOUNT</span></TableCell>
                                        <TableCell><span className="font-semibold">USAGE</span></TableCell>
                                        <TableCell><span className="font-semibold">TOTAL USED</span></TableCell>
                                        <TableCell><span className="font-semibold">EXPIRY DATE</span></TableCell>
                                        <TableCell><span className="font-semibold">ACTION</span></TableCell>
                                        <TableCell><span className="font-semibold">STATUS</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {couponList?.map((val, idx) => (
                                        <TableRow
                                            key={val.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row"> {val.name}</TableCell>
                                            <TableCell>{val.accountType}</TableCell>
                                            <TableCell>{val.couponCode}</TableCell>
                                            <TableCell>{val.couponType}</TableCell>
                                            <TableCell>S${val.couponAmount}</TableCell>
                                            <TableCell>{val.couponUsage}</TableCell>
                                            <TableCell>{val.couponUsed}</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell><FontAwesomeIcon className="cursor-pointer"
                                            onClick={() => navigate(`/modules/coupons-management/edit?id=${val.id}`)}
                                            icon={faFilePen}/></TableCell>
                                            <TableCell>
                                                    <Switch
                                                        size="small"
                                                        checked={val.status == "Active" ? true : false}
                                                        onChange={(e) => {handleChangeStatusCoupon(val)}}
                                                    />
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default CouponsManagementPage