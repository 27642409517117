
export const CustomerInitialData: any = {
  countryCode: '',
  id: '',
  accountName: "",
  firstName: "",
  lastName: "",
  accountType: "CUSTOMER",
  roleType: null,
  email: "",
  // website: "",
  password: "",
  phoneNumber: "",
  location: "",
  // isGnetSupplier: "",
  // isFeaturedSupplier: "",
  isActive: true,
  // billingDetails: "",
  // companyDetails: "",
  // driverDetails: "",
  // tripDetails: "",
  // creditTermDetails: "",
  // businessDetails: "",
  // otherSettings: "",
  // version: "",
  // createdBy: "",
  // createdAt: "",
  // updatedBy: "",
  // updatedAt: "",
  // deletedBy: "",
  // deletedAt: "",
  rolesDetails: {
    id: "7a5fc758-d4eb-42b3-b487-cca3dc538443",
    // rolesName: "",
    // rolesDescription: "",
    // version: "",
    // status: "",
    // createdBy: "",
    // createdAt: "",
    // updatedBy: "",
    // updatedAt: "",
    // deletedBy: "",
    // deletedAt: ""
  },
  // gnetProviderName: "",
  // gnetRequesterName: ""
}

export interface TripData {
    statusAndRefNo: string;
    serviceType: string;
    dateTime: string;
    tripAmount: number;
  }
  
  export const exampleData: TripData[] = [
    { statusAndRefNo: 'REF12345', serviceType: 'Hourly', dateTime: '2024-07-07T10:00:00', tripAmount: 25.50 },
    { statusAndRefNo: 'REF12346', serviceType: 'Hourly', dateTime: '2024-07-07T11:00:00', tripAmount: 35.00 },
    { statusAndRefNo: 'REF12347', serviceType: 'Hourly', dateTime: '2024-07-07T12:00:00', tripAmount: 15.20 },
    { statusAndRefNo: 'REF12348', serviceType: 'Hourly', dateTime: '2024-07-07T13:00:00', tripAmount: 12.75 },
    { statusAndRefNo: 'REF12349', serviceType: 'Hourly', dateTime: '2024-07-07T14:00:00', tripAmount: 8.50 },
    { statusAndRefNo: 'REF12350', serviceType: 'Hourly', dateTime: '2024-07-07T15:00:00', tripAmount: 100.00 },
    { statusAndRefNo: 'REF12351', serviceType: 'Hourly', dateTime: '2024-07-07T16:00:00', tripAmount: 250.00 },
    { statusAndRefNo: 'REF12352', serviceType: 'Hourly', dateTime: '2024-07-07T17:00:00', tripAmount: 45.00 },
    { statusAndRefNo: 'REF12353', serviceType: 'Hourly', dateTime: '2024-07-07T18:00:00', tripAmount: 5.00 },
    { statusAndRefNo: 'REF12354', serviceType: 'Hourly', dateTime: '2024-07-07T19:00:00', tripAmount: 3.50 }
  ];