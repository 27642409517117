import { AirportDetail, PricePlan } from "../model/Price.model";

export const SellingPriceInitialData: PricePlan = {
    id: "",
    code: "",
    countryCode: "",
    stateCode: "",
    cityCode: "",
    currencyCode: "",
    accountType: "",
    accountName: "",
    airportArrivalDetails: [],
    airportDepartureDetails: [],
    pointToPointDetails: [],
    hourlyDetails: [],
    extraChargesDetails: [],
    addOnServicesDetails: undefined,
    status: "",
    version: 0,
    createdBy: "",
    createdAt: "",
    updatedBy: "",
    updatedAt: "",
    deletedBy: null,
    deletedAt: null
}

export const AirportDetailInitialData: AirportDetail = {
    id: "",
    airportCode: "",
    zoneCode: "",
    economy4SeaterRate: 0,
    economy6SeaterRate: 0,
    comfort7ToyotaNoahRate: 0,
    comfort5SeaterToyotaCamryOrEquivalentRate: 0,
    bus13SeaterHiaceRate: 0,
    businessSedanEClassRate: 0,
    businessMpvAlphardRate: 0,
    businessVanVClassRate: 0,
    firstClassMercedezSClassRate: 0,
    seater40BusRate: 0,
    twMercedezVClassRate: 0,
    malaysiaToyotaAlphardRate: 0,
    eqeEClassMercedezRate: 0,
    tollRate: 0,
}

export const UserType = [
    { label: 'Travel Agent', value: 'TRAVELAGENT' },
    { label: 'Corporate', value: 'Corporate' },
    { label: 'Customer', value: 'CUSTOMER' },
    { label: 'Guest', value: 'GUEST' },
]