import { axiosBooking } from "../../../services/axiosInstance"
import { axiosInstance } from "../../../services/axiosPayment"

export const getTripList = (status: string) => {
    return axiosBooking.get(`/trip/?pageableSize=10&pageablePage=0&sortingPage=ASC&status=${status}`)
}

export const getCouponsList = () => {
    return axiosInstance.get('/coupon/?pageableSize=10&pageablePage=0&sortingPage=ASC&status=')
}

export const getCouponById = (id: string) => {
    return axiosInstance.get(`/coupon/id/${id}`)
}

export const createCoupon = (payload: any) => {
    return axiosInstance.post(`coupon/create`, payload)
}

export const editCoupon = (id: string, payload: any) => {
    return axiosInstance.put(`/coupon/update/id/${id}`, payload)
}

export const updateCoupon = (param: any) => {
    return axiosInstance.put(`/coupon/update/id/${param?.id}`, param)
}

export const getDiscountList = () => {
    return axiosInstance.get('/discount/?pageableSize=10&pageablePage=0&sortingPage=ASC&status=')
}

export const getDiscountById = (id: string) => {
    return axiosInstance.get(`/discount/id/${id}`)
}

export const createDiscount = (payload: any) => {
    return axiosInstance.post(`discount/create`, payload)
}

export const editDiscount = (id: string, payload: any) => {
    return axiosInstance.put(`/discount/update/id/${id}`, payload)
}

export const updateDiscount = (param: any) => {
    return axiosInstance.put(`/discount/update/id/${param?.id}`, param)
}