import { faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { createPricePlan, getPricePlanById, updatePricePlan } from "../../../../services/axiosPayment"
import LoadingPage from "../../../common/Loading.page"
import { PricePlan } from "../model/Price.model"
import AirportArrival from "./components/AirportArrival"
import AirportDeparture from "./components/AirportDeparture"
import Hourly from "./components/Hourly"
import PointToPoint from "./components/PointToPoint"
import { SellingPriceInitialData, UserType } from "./formData"

// type EditSellingPriceProps = {
//     data: any,
//     component: React.ComponentType<AirportArrivalProps>
// }


const EditSellingPricePage = () => {

    type tabType = 'ARR' | 'DEP' | 'P2P' | 'HRL'

    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState<boolean>(false)
    const [pricePlanDetail, setPriceDetail] = useState<PricePlan[]>([])
    const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan>()
    const [showModalPricePlan, setShowModalPricePlan] = useState<boolean>(false)
    const [selectedName, setSelectedName] = useState<string>('')
    const [activeTab, setActiveTab] = useState<tabType>('ARR')
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')

    const parameter = 'Hello, World!';

    const id: string = queryParams.get('id') ?? ''

    const tab = [
        { label: 'Airport Arrival', value: 'ARR' },
        { label: 'Airport Departure', value: 'DEP' },
        { label: 'Point To Point', value: 'P2P' },
        { label: 'Hourly', value: 'HRL' },
    ]

    const formik = useFormik({
        initialValues: SellingPriceInitialData,
        enableReinitialize: true,
        onSubmit: (values) => {
            const param = {
                ...formik.values
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitSellingPrice(addParam)
            } else {
                updateSellingPrice(id, param)
            }
            
        },
    });

    const submitSellingPrice = (body: any) => {
        setLoading(true)
            createPricePlan(body)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success("Success create Price Plan")
                        navigate('/price/selling-price', { replace: true })
                    }
                })
                .catch((err) => {
                    toast.error("Failed to create Price Plan")
                })
                .finally(() => {
                    setLoading(false)
                })
            console.log('PAYLOAD SAVE', body)
    }

    const updateSellingPrice = (id: string, body: any) => {
        setLoading(true)
        updatePricePlan(id, body)
            .then((res) => {
                // if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success update Price Plan")
                    navigate('/price/selling-price', { replace: true })
                // }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to update Price Plan")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const fetchPriceById = (id: string) => {
        getPricePlanById(id)
            .then((res: any) => {
                console.log(res);
                setPriceDetail(res)
                const sellingPrice: any = res
                Object.entries(sellingPrice).map(([key, values]) => {
                    formik.setFieldValue(`${key}`, values)
                })
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    
    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
            fetchPriceById(id)
        }
    }, [id])

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Price Plan</Typography>
                </div>
            </div>
            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Hub Details
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 h-fit">
                    <div className="grid grid-cols-5 gap-4 p-4">
                        <div>
                            <InputLabel className="text-sm -mb-2">Code*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="code"
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div>
                            <InputLabel className="text-sm -mb-2">awd*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="airportArrivalDetails[0].airportCode"
                                name="airportArrivalDetails[0].airportCode"
                                value={formik.values.airportArrivalDetails[0]?.airportCode}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">Country*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="countryCode"
                                name="countryCode"
                                value={formik.values.countryCode}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">State*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="stateCode"
                                name="stateCode"
                                value={formik.values.stateCode}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">Name*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="accountName"
                                name="accountName"
                                value={formik.values.accountName}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">Currency*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="currencyCode"
                                name="currencyCode"
                                value={formik.values.currencyCode}
                                onChange={formik.handleChange}
                            />
                        </div>

                        <div>
                            <InputLabel className="text-sm">User Types*</InputLabel>
                            <Select 
                                size="small"
                                className="w-full h-10"
                                 id="accountType"
                                name="accountType"
                                onChange={(e) => {
                                    formik.handleChange(e)
                                }}
                                value={formik.values.accountType}
                                >
                                     {UserType?.map((user) => (
                                        <MenuItem value={user.value}>{user.label}</MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-row-reverse p-4">
                        <Button type="submit" className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}>
                            <FontAwesomeIcon icon={faSave} />
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
            <div className="p-4">
                <div className="flex flex-row">
                    {tab.map((tabs) => (
                        <div onClick={() => setActiveTab(tabs.value as tabType)}
                            className={`hover:cursor-pointer hover:opacity-80 
                        ${tabs.value === activeTab ? 'bg-[#0A2638]' : 'bg-[#EDBA37]'}
                        ${tabs.value === activeTab ? 'text-[#EDBA37]' : 'text-[#0A2638]'}
                        px-8 py-4 drop-shadow-xl rounded-t-lg`}>
                            {tabs.label}
                        </div>
                    ))}
                </div>
                {activeTab === 'ARR' && (<AirportArrival dataAirportArrival={formik.values}/>)}
                {activeTab === 'DEP' && (<AirportDeparture data={formik.values}/>)}
                {activeTab === 'P2P' && (<PointToPoint data={formik.values}/>)}
                {activeTab === 'HRL' && (<Hourly data={formik.values}/>)}
                
            </div>
            </form>
        </div>
    )
}

export default EditSellingPricePage