import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import LoadingPage from "../common/Loading.page"
import { CouponInitialData } from "./formData"
import { createCoupon, editCoupon, getCouponById } from "./services/ModulesServices.services"

const EditCouponPage = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')


    const formik = useFormik({
        initialValues: CouponInitialData,
        enableReinitialize: true,
        onSubmit: (values) => {
            const param = {
                ...formik.values
            }
            if (pageAction === 'ADD') {
                type JsonObject = { [key: string]: any };
                let addParam: Partial<JsonObject> = { ...param }
                delete addParam['id']
                submitCoupon(addParam)
            } else {
                updateCoupon(id, param)
            }
            
        },
    });

    const submitCoupon = (body: any) => {
        setLoading(true)
            createCoupon(body)
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success("Success create Coupon")
                        navigate('/modules/coupons-management', { replace: true })
                    }
                })
                .catch((err) => {
                    toast.error("Failed to create Coupon")
                })
                .finally(() => {
                    setLoading(false)
                })
            console.log('PAYLOAD SAVE', body)
    }

    const updateCoupon = (id: string, body: any) => {
        setLoading(true)
        editCoupon(id, body)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false)
                    toast.success("Success update Coupon")
                    navigate('/modules/coupons-management', { replace: true })
                }
            })
            .catch((err: any) => {
                console.log('ERR', err)
                toast.error("Failed to update Coupon")
            })
            .finally(() => {
                setLoading(false)
            })
        console.log('PAYLOAD SAVE', body)

    }

    const fetchCouponDetail = (id: string) => {
        setLoading(true)
        getCouponById(id)
            .then((res) => {
                console.log(res)
                const coupon: any = res.data
                Object.entries(coupon).map(([key, values]) => {
                    formik.setFieldValue(`${key}`, values)
                })
            })
            .catch((err) => {
                console.log('RES', err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
            fetchCouponDetail(id)
        }
    }, [navigate])

    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">{`${pageAction === 'ADD' ? 'Add' : 'Edit'} Coupon`}</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Coupon
                    </span>
                </div>
                <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div className="grid grid-cols-6 gap-2">
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Coupon Name*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="name"
                                name="name"
                                placeholder="Coupon Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Coupon Description</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="description"
                                name="description"
                                placeholder="Coupon Description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Coupon Type</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="couponType"
                                name="couponType"
                                placeholder="Coupon Type"
                                value={formik.values.couponType}
                                onChange={formik.handleChange}
                            >
                                {[
                                    { label: 'User Defined', value: 'userdefined' },
                                    { label: 'Dynamic', value: 'dynamic' },
                                    { label: 'Flat Discount', value: 'Flat Discount' }
                                ].map((val: any) => (
                                    <MenuItem value={val.value}>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        {/* <div className="flex flex-col">
                            <InputLabel className="text-sm">Discount Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Coupon Type"
                                value={formik.values.couponType}
                                onChange={formik.handleChange}
                            >
                                {[
                                    { label: 'Fix Amount Discount', value: 'fix' },
                                    { label: 'Percentage Discount', value: 'percent' }
                                ].map((val: any) => (
                                    <MenuItem>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div> */}
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Coupon Code*</InputLabel>
                            <TextField
                                type="text"
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="couponCode"
                                name="couponCode"
                                placeholder="Coupon Discount"
                                value={formik.values.couponCode}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Coupon Discount*</InputLabel>
                            <TextField
                                type="number"
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="couponAmount"
                                name="couponAmount"
                                placeholder="Coupon Discount"
                                value={formik.values.couponAmount}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Expiry Date</InputLabel>
                            <TextField
                                type="date"
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Expiry Date"
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Customer Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="accountType"
                                name="accountType"
                                placeholder="Coupon Type"
                                value={formik.values.accountType}
                                onChange={formik.handleChange}
                            >
                                {[
                                    { label: 'All', value: 'all' },
                                    { label: 'Corporate', value: 'corporate' },
                                    { label: 'Travel Agent', value: 'TA' },
                                    { label: 'Customers', value: 'Customers' },
                                    { label: 'Premium', value: 'Premium' },

                                ].map((val: any) => (
                                    <MenuItem value={val.value}>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Max Number of Use*</InputLabel>
                            <TextField
                                type="number"
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="couponUsage"
                                name="couponUsage"
                                placeholder="Max Number of Use"
                                value={formik.values.couponUsage}
                                onChange={formik.handleChange}
                            ></TextField>
                        </div>

                    </div>
                </div>
            </div>
            </form>
        </div>
    )
}

export default EditCouponPage